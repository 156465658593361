import infoCardElement from '../../../components/Utils/Cards/InfoCardMethods';

const generalObject = ({ insurancePolicy, isForm, setModalShow }) => {
  const {
    accountId,
    accountName,
    accountNationalIdentification,
    additionalClauses,
    beneficiaryPersonName,
    beneficiaryPersonAddress,
    beneficiaryPersonNationalIdentification,
    collectionEmail,
    contractFile,
    contractNumber,
    executiveManagerLabel,
    externalBroker,
    hiringPersonName,
    hiringPersonAddress,
    hiringPersonNationalIdentification,
    insuranceBrokerCompanyName,
    insuranceBrokerName,
    insuranceCompanyName,
    insuredPersonAddress,
    insuredPersonName,
    insuredPersonNationalIdentification,
    paymentPlanName,
    policyNumber,
    policyType,
    responsibleEmail,
    renewablePolicyNumber,
    renewablePolicyId,
    translatedCreatedFrom,
    translatedNicoCode,
    translatedPaymentMethod
  } = insurancePolicy;

  const isProposal = policyType === 'proposal';
  const createContractFromProposal = isProposal && contractNumber && contractFile;

  const object = {};

  object.accountName = infoCardElement({
    link: true,
    key: 'Cuenta',
    value: accountName,
    url: `/admin/accounts/${accountId}`
  });
  if (isForm)
    object.policyNumber = infoCardElement({
      string: true,
      key: isProposal ? 'N° propuesta' : 'N° póliza',
      value: policyNumber
    });
  else
    object.accountNationalIdentification = infoCardElement({
      string: true,
      key: 'RUT Cuenta',
      value: accountNationalIdentification
    });
  if (createContractFromProposal)
    object.contractNumber = infoCardElement({
      string: true,
      key: 'N° póliza',
      value: contractNumber
    });
  object.nicoCode = infoCardElement({
    string: true,
    key: 'Código NICO',
    value: translatedNicoCode
  });
  if (translatedCreatedFrom) {
    object.createdFrom = infoCardElement({
      string: true,
      key: 'Tipo de venta',
      value: translatedCreatedFrom
    });

    if (translatedCreatedFrom === 'Renovación' && renewablePolicyNumber)
      object.renewablePolicyNumber = infoCardElement({
        link: true,
        key: 'N° de póliza anterior',
        value: renewablePolicyNumber,
        url: `/admin/insurance_policies/${renewablePolicyId}`
      });
  }
  object.insuranceCompanyName = infoCardElement({
    string: true,
    key: 'Aseguradora',
    value: insuranceCompanyName
  });
  // object.subjectMatter = infoCardElement({
  //   string: true,
  //   key: 'Materia',
  //   value: subjectMatter
  // });
  // object.subjectMatterAddress = infoCardElement({
  //   string: true,
  //   key: 'Dirección de riesgo',
  //   value: subjectMatterAddress || 'No especificada'
  // });
  if (additionalClauses)
    object.additionalClauses = infoCardElement({
      file: true,
      item: {
        title: 'Cláusulas adicionales',
        name: 'Ver',
        onClick: () => setModalShow(true)
      }
    });

  if (hiringPersonName)
    object.hiringPerson = infoCardElement({
      string: true,
      key: 'Contratante',
      value: `${hiringPersonName} ${hiringPersonNationalIdentification}`
    });
  if (hiringPersonAddress)
    object.hiringPersonAddress = infoCardElement({
      string: true,
      key: 'Dirección contratante',
      value: hiringPersonAddress
    });
  if (insuredPersonName)
    object.insuredPerson = infoCardElement({
      string: true,
      key: 'Asegurado',
      value: `${insuredPersonName} ${insuredPersonNationalIdentification}`
    });
  if (insuredPersonAddress)
    object.insuredPersonAddress = infoCardElement({
      string: true,
      key: 'Dirección asegurado',
      value: insuredPersonAddress
    });
  if (beneficiaryPersonName)
    object.beneficiaryPerson = infoCardElement({
      string: true,
      key: 'Beneficiario',
      value: `${beneficiaryPersonName} ${beneficiaryPersonNationalIdentification}`
    });
  if (beneficiaryPersonAddress)
    object.beneficiaryPersonAddress = infoCardElement({
      string: true,
      key: 'Dirección beneficiario',
      value: beneficiaryPersonAddress
    });
  object.paymentPlan = infoCardElement({
    string: true,
    key: 'Plan de pago',
    value: paymentPlanName
  });
  object.paymentMethod = infoCardElement({
    string: true,
    key: 'Forma de pago',
    value: translatedPaymentMethod
  });
  if (responsibleEmail)
    object.responsibleEmail = infoCardElement({
      string: true,
      key: 'Contacto comercial',
      value: responsibleEmail
    });
  if (collectionEmail)
    object.collectionEmail = infoCardElement({
      string: true,
      key: 'Contacto cobranza',
      value: collectionEmail
    });
  object.insuranceBroker = infoCardElement({
    string: true,
    key: 'Corredor',
    value: `${insuranceBrokerCompanyName}/${insuranceBrokerName}`
  });
  if (executiveManagerLabel)
    object.executiveManager = infoCardElement({
      string: true,
      key: 'Ejecutivo',
      value: executiveManagerLabel
    });
  if (externalBroker)
    object.externalBroker = infoCardElement({
      string: true,
      key: 'Corredor externo',
      value: externalBroker
    });

  return object;
};

export default generalObject;
